import classnames from 'classnames'
import * as React from 'react'
import { Tooltip, type PlacesType } from 'react-tooltip'
import { HelpTextIcon } from 'src/assets/icons/customIcons/HelpTextIcon'
import { useTheme } from 'src/state/providers/Theme'

interface Props {
  readonly label?: React.ReactNode
  readonly labelClassName?: string
  readonly children?: React.ReactNode
  readonly placement?: PlacesType
  readonly disabled?: boolean
}

function PopoverComponent({ children, label, labelClassName, placement, disabled }: Props): React.ReactElement | null {
  const id = React.useId()
  const theme = useTheme()

  return (
    <>
      <button
        type="button"
        data-tooltip-id={id}
        data-tooltip-event="click focus"
        data-tooltip-event-off="blur"
        className={classnames(
          'group relative ml-2 rounded-full align-middle duration-100 ease-in-out after:absolute after:inset-[-5px] after:content-[""] hover:bg-hover-card',
          {
            '!cursor-not-allowed opacity-50': disabled,
            labelClassName,
          }
        )}
        disabled={disabled}
      >
        {label ?? <HelpTextIcon />}
      </button>
      <Tooltip
        id={id}
        place={placement}
        variant={theme === 'dark' ? 'dark' : 'light'}
        className="z-[99] mb-1 mb-3 flex max-w-[60%] whitespace-pre-line break-words p-1 shadow-[0px_1px_14px_rgba(0,0,0,0.2)] sm:max-w-[calc(100vw-40px)] xxs:max-w-[calc(100vw-40px)] xs:max-w-[calc(100vw-40px)]"
        openOnClick
        clickable
        globalCloseEvents={{
          clickOutsideAnchor: true,
          escape: true,
        }}
        closeEvents={{
          click: true,
        }}
      >
        {children}
      </Tooltip>
    </>
  )
}
export default PopoverComponent
