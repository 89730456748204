import { format, parseISO } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import { NewsIcon } from 'src/assets/icons/customIcons/News'
import { RedArrowIcon } from 'src/assets/icons/customIcons/RedArrow'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { News } from 'src/tailwind/components/News'
import { Section } from 'src/tailwind/components/Section'
import NoContent from 'src/views/components/NoContent'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function HomeNewsSection() {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const isLimitedFunctionality = userState.data?.regime.functionalIsLimited
  if (isLimitedFunctionality) return null

  return (
    <Section
      homePage
      title={t('news:news')}
      icon={<NewsIcon />}
      rightElement={
        <Link
          to={`/${locale}/news`}
          className="group relative flex cursor-pointer items-center rounded-card py-[7px] pl-4 pr-7 hover:bg-hover-card"
        >
          <span className="mr-[7px] text-primaryTextColor">{t('news:all_news')}</span>
          <div className="absolute right-[13px] duration-500 ease-in-out group-hover:mr-[-5px]">
            <RedArrowIcon />
          </div>
        </Link>
      }
      data-testid="news"
    >
      <SuspenseWrapper>
        <NewsContent isLimitedFunctionality={isLimitedFunctionality} />
      </SuspenseWrapper>
    </Section>
  )
}

function NewsContent({ isLimitedFunctionality }: { isLimitedFunctionality?: boolean }): React.ReactElement {
  const locale = useLocale()
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const fetching = useApi({
    endpoint: Api.getNews,
    params: React.useMemo(() => ({ headers, query: { perPage: 2, page: 1 } }), [headers]),
    shouldFetch: isLimitedFunctionality === false,
  })
  const data = fetching.data?.data
  if (data == null || data.length === 0) {
    return <NoContent header={t('error:news_not_found')} image={Warning} />
  }
  return (
    <ul className="mb-0 flex gap-3 pl-0 xxs:flex-col xs:flex-col">
      {data.map((n) => {
        return (
          <News
            to={`/${locale}/news/${n.id}`}
            key={n.id}
            title={n.title}
            date={format(parseISO(n.createdAt), 'd MMMM yyyy, HH:mm', {
              locale: dateLocale,
            })}
            className="w-full"
            data-testid={`news/${n.id}`}
          />
        )
      })}
    </ul>
  )
}
