import classnames from 'classnames'
import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { ArrowNext } from 'src/assets/icons/customIcons/ArrowNext'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'

interface Page {
  readonly link: string
  readonly title: string
  readonly end?: boolean
}

interface Options {
  readonly Icon: (props: { [key: string]: unknown }) => React.ReactElement
  readonly title?: string
  readonly pages: Page[]
  readonly filter?: React.ReactElement | null
  readonly subBreadcrubms?: subBreadcrubmsItem[] | null
}

interface subBreadcrubmsItem {
  page: string | undefined
  path: string
}
export default function SubPageLayout({ Icon, pages, title, filter, subBreadcrubms }: Options): JSX.Element {
  const linkRef = React.useRef<any>(null)
  const t = useTranslatable()

  React.useEffect(() => {
    if (linkRef?.current) {
      linkRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      })
    }
  }, [])

  return (
    <Section icon={<Icon />} title={title}>
      {filter}
      {(() => {
        if (subBreadcrubms != null) {
          return (
            <ul className="mt-[14px] inline-flex text-captionColor" data-testid="breadcrubms">
              <li className="items-center text-xs !text-captionColor">
                <Link to="/" className="hover:opacity-70">
                  {t('system:home_page')}{' '}
                </Link>
              </li>
              {subBreadcrubms?.map((item, key) => (
                <li key={key} className="items-center text-xs !text-captionColor">
                  <div className="flex items-center justify-center">
                    <span className="px-1 pt-[3px]">
                      <ArrowNext />
                    </span>
                    <Link to={item.path} className="hover:opacity-70">
                      {item.page}
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          )
        }
      })()}
      <div className="flex flex-col items-start">
        <div className="mb-2 w-full overflow-x-scroll">
          <ul className="relative mb-2 mt-4 flex w-max min-w-full items-center" data-testid="subPageLayoutTabs">
            {pages.map((page, index) => {
              return (
                <li className="z-10 min-w-[120px] text-center" style={{ width: `${100 / pages.length}%` }} key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      classnames('group pointer-events-auto w-full', {
                        disabled: isActive,
                        active: isActive,
                      })
                    }
                    style={{ lineBreak: 'auto' }}
                    to={page.link}
                    end={page.end ?? true}
                  >
                    {({ isActive }) => (
                      <div
                        className="relative flex h-[60px] w-full flex-col justify-between"
                        ref={(ref) => {
                          if (isActive) {
                            linkRef.current = ref
                          }
                        }}
                      >
                        <div className="relative h-[30px]">
                          <span
                            className={`absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 font-semibold text-primaryTextColor duration-150 ease-in-out hover:opacity-100 ${
                              isActive ? 'opacity-100' : 'opacity-50 group-hover:!opacity-80'
                            }`}
                            data-testid={`subPageLink-${page.link}`}
                          >
                            {page.title}
                          </span>
                        </div>
                        <div
                          data-testid={`subPageLink-${page.link}-underline`}
                          className={`absolute bottom-0 left-1/2 z-10 h-[5px] -translate-x-1/2 duration-300 ease-in-out group-hover:bg-[#b804349c] ${
                            isActive ? 'w-full bg-[#B80433]' : 'w-0 group-hover:w-full'
                          }`}
                        />
                      </div>
                    )}
                  </NavLink>
                </li>
              )
            })}
            <div className="absolute bottom-0 h-[5px] w-full bg-borderGrey" />
          </ul>
        </div>
        <Outlet />
      </div>
    </Section>
  )
}
