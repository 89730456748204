import { useField } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'

import tinymce from 'tinymce'

import { Editor } from '@tinymce/tinymce-react'

import 'tinymce/icons/default'
import 'tinymce/models/dom'
import 'tinymce/themes/silver'

// import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
// import 'tinymce/plugins/autolink'
// import 'tinymce/plugins/autoresize'
// import 'tinymce/plugins/autosave'
// import 'tinymce/plugins/bbcode'
// import 'tinymce/plugins/charmap'
// import 'tinymce/plugins/code'
// import 'tinymce/plugins/codesample'
// import 'tinymce/plugins/colorpicker'
// import 'tinymce/plugins/contextmenu'
// import 'tinymce/plugins/directionality'
// import 'tinymce/plugins/emoticons'
// import 'tinymce/plugins/fullpage'
// import 'tinymce/plugins/fullscreen'
// import 'tinymce/plugins/help'
// import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
// import 'tinymce/plugins/imagetools'
// import 'tinymce/plugins/importcss'
// import 'tinymce/plugins/insertdatetime'
// import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/link'
// import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
// import 'tinymce/plugins/nonbreaking'
// import 'tinymce/plugins/noneditable'
// import 'tinymce/plugins/pagebreak'
// import 'tinymce/plugins/paste'
// import 'tinymce/plugins/preview'
// import 'tinymce/plugins/print'
// import 'tinymce/plugins/quickbars'
// import 'tinymce/plugins/save'
// import 'tinymce/plugins/searchreplace'
// import 'tinymce/plugins/spellchecker'
// import 'tinymce/plugins/tabfocus'
import { useTheme } from 'src/state/providers/Theme'
import 'tinymce/plugins/table'
// import 'tinymce/plugins/template'
// import 'tinymce/plugins/textcolor'
// import 'tinymce/plugins/textpattern'
// import 'tinymce/plugins/toc'
// import 'tinymce/plugins/visualblocks'
// import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'

import contentCSS from 'src/sass/_dark-theme.scss?raw'
import oxideDark from 'tinymce/skins/ui/oxide-dark/skin.min.css?raw'
import oxide from 'tinymce/skins/ui/oxide/skin.min.css?raw'
import { useFormData } from './formik/Form'

// @ts-expect-error todo
window.tinymce = tinymce

interface Props {
  readonly name: string
  readonly required?: boolean
}

export default function FormikTinyMce(props: Props): React.ReactElement {
  const theme = useTheme()
  const t = useTranslatable()

  const [field, meta, helpers] = useField({
    name: props.name,
    validate(value: string) {
      if (props.required === true) {
        if (value === '') {
          return t('common:required')
        }
      }
    },
  })

  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []

  return (
    <div className="mb-4 p-0">
      <style>{theme === 'dark' ? oxideDark : oxide}</style>
      <Editor
        value={field.value}
        onEditorChange={(value) => void helpers.setValue(value)}
        key={theme}
        init={{
          branding: false,
          height: 300,
          menubar: false,
          content_style: theme === 'dark' ? 'body { color: white };' + contentCSS : '',
          skin: false,
          plugins: ['table', 'link', 'media', 'image', 'anchor', 'wordcount'],
          toolbar: `
            undo redo | formatselect |
            bold italic backcolor |
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent |
            link table media image anchor removeformat | wordcount
          `,
        }}
      />
      {meta.touched && meta.error != null ? (
        <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
      ) : null}
      {errors.map((error, i) => (
        <div key={i} className="mt-2 text-[13px] text-errorMessages">
          {error}
        </div>
      ))}
    </div>
  )
}
