import * as ProDuotoneSvgIcons from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import FileDownload from 'src/views/components/FileDownload'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function SingleNewsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('news:news')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const { newsId } = useParams()
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const { pathname, state } = useLocation()
  const locale = useLocale()
  const userState = useUserState()
  const news = useApi({
    endpoint: Api.getSingleNews,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          newsId: newsId!,
        },
      }),
      [headers, newsId]
    ),
    shouldFetch: userState.data?.regime.functionalIsLimited === false,
  })

  useUserStateMutateOnMount()

  const htmlContainer = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (htmlContainer.current != null) {
      for (const el of htmlContainer.current.getElementsByTagName('a')) {
        if (el.origin !== location.origin) {
          el.target = '_blank'
        }
      }
    }
  }, [])
  if (news.data == null) return null
  const isNotification = state?.isNotification
  const link = `/${locale}/${isNotification ? 'notifications' : 'news'}`
  const title = isNotification ? t('notification:notifications') : t('news:news')
  const breadcrumbsItems = [
    { page: title, path: link },
    { page: news.data.title, path: pathname },
  ]

  return (
    <Section
      title={title}
      icon={<FontAwesomeIcon icon={ProDuotoneSvgIcons.faBullhorn} />}
      rightElement={<BackButton link={link} />}
      breadcrubms={breadcrumbsItems}
    >
      <div className="flex flex-col items-start overflow-x-scroll">
        <div className="my-4 flex w-full flex-col items-start justify-center">
          <span className="mb-2 font-semibold text-primaryTextColor">{news.data.title}</span>
          <div className="mr-3 flex items-center text-caption last:mr-0">
            <ClockIcon />
            <span className="ml-2" data-testid="date">
              {format(parseISO(news.data.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
            </span>
          </div>
        </div>
        <div
          ref={htmlContainer}
          className="grow overflow-auto text-captionColor"
          dangerouslySetInnerHTML={{ __html: news.data.text }}
        />
      </div>
      {news.data.files != null && news.data.files.length > 0 && (
        <div className="mt-4 flex items-start border-t border-borderGrey py-4">
          <p className="mr-2 font-semibold">{t('file:attached_files') + ':'}</p>
          <div className="flex flex-wrap">
            {news.data.files.map((file) => (
              <div key={file.id} className="mr-2 flex justify-between" data-testid={`file/${file.id}`}>
                <div className="flex flex-col">
                  <FileDownload
                    mediaFile={file.mediaFile}
                    title={file.mediaFile.title}
                    type={file.mediaFile.extension}
                    size={file.mediaFile.size}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Section>
  )
}
