import * as React from 'react'
import { toast } from 'react-toastify'
import { getMediaFilesDownload, type MediaFile } from 'src/api'
import { formatFileSize } from 'src/helpers/fns'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import FileIcon from './FileIcon'
import Loader from './Loader'

interface Props {
  readonly mediaFile: MediaFile
  readonly title: string
  readonly type: string
  readonly size: number
}

export default function FileDownload({ mediaFile, title, type, size }: Props): JSX.Element {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const downloadMediaFile = await getMediaFilesDownload({
        headers,
        args: {
          id: mediaFile.id,
        },
      })

      location.replace(downloadMediaFile.url)
    } catch (error) {
      toast.error(t('error:an_error_occurred'))
    }
    setIsLoading(false)
  }

  return (
    <button onClick={() => void fetchData()} disabled={isLoading} className="mr-2 text-left">
      {isLoading ? <Loader className="flex" style={{ margin: 'auto' }} /> : null}
      <div className="flex">
        <FileIcon type={type} />
        <div className="ml-1" data-testid="fileDownloadDetails">
          <p className="text-bodyText text-primaryBlueLink">{`${title}` + '.' + `${type}`}</p>
          <span className="text-tiny text-lightPrimaryIconText">{formatFileSize(size)}</span>
        </div>
      </div>
    </button>
  )
}
