import React from 'react'
import { toast } from 'react-toastify'
import { Actions } from 'react-use/lib/useSet'
import * as Api from 'src/api'
import { DeleteIcon } from 'src/assets/icons/customIcons/Delete'
import { EnvelopeIcon } from 'src/assets/icons/customIcons/Envelope'
import { EnvelopeOpenIcon } from 'src/assets/icons/customIcons/EnvelopeOpen'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Swal from 'sweetalert2'

interface Props {
  selections: Set<string>
  selectionActions: Actions<string>
  refetchMessages: () => void
  semId: string
  isOutboxPage?: boolean
  userState: ReturnType<typeof useUserState>
}

export default function MessageActionButtons({
  selections,
  selectionActions,
  refetchMessages,
  semId,
  isOutboxPage,
  userState,
}: Props) {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const DELETE_SELECTED = React.useCallback(async () => {
    try {
      await Api.deleteMessages({
        headers,
        body: {
          semId,
          messageIds: Array.from(selections).join(','),
        },
      })
      selectionActions.reset()
      void refetchMessages()
      await userState.mutate()
      toast.success(t('common:updated_successfully'))
    } catch (error) {
      toast.error(t('error:an_error_occurred'))
    }
  }, [headers, semId, selections, selectionActions, refetchMessages, userState, t])

  const MARK_SELECTED_AS_READ = React.useCallback(async () => {
    try {
      await Api.patchMessagesMarkRead({
        headers,
        body: {
          semId,
          messageIds: Array.from(selections).join(','),
        },
      })
      void refetchMessages()
      await userState.mutate()
      toast.success(t('common:updated_successfully'))
    } catch (error) {
      toast.error(t('error:an_error_occurred'))
    }
  }, [headers, semId, selections, refetchMessages, userState, t])

  const MARK_SELECTED_AS_UNREAD = React.useCallback(async () => {
    try {
      await Api.patchMessagesMarkUnread({
        headers,
        body: {
          semId,
          messageIds: Array.from(selections).join(','),
        },
      })
      void refetchMessages()
      await userState.mutate()

      toast.success(t('common:updated_successfully'))
    } catch (error) {
      toast.error(t('error:an_error_occurred'))
    }
  }, [headers, semId, selections, refetchMessages, userState, t])
  return (
    <div className="flex justify-end">
      {selections.size > 0 && (
        <>
          {!isOutboxPage && (
            <>
              <div className="px-1">
                <button
                  type="button"
                  className="flex rounded-[18px] p-[10px] text-xs"
                  onClick={() => void MARK_SELECTED_AS_READ()}
                  data-testid="markSelectedAsRead"
                >
                  <span className="w-[18px]">
                    <EnvelopeOpenIcon />
                  </span>
                  <span className="pl-2 text-primaryTextColor">{t('common:mark_as_read')}</span>
                </button>
              </div>
              <div className="px-1">
                <button
                  type="button"
                  className="flex rounded-[18px] p-[10px] text-xs"
                  onClick={() => void MARK_SELECTED_AS_UNREAD()}
                  data-testid="markSelectedAsUnread"
                >
                  <span className="w-[18px]">
                    <EnvelopeIcon />
                  </span>
                  <span className="pl-2 text-primaryTextColor">{t('common:mark_as_unread')}</span>
                </button>
              </div>
            </>
          )}
          <div className="px-1">
            <button
              type="button"
              className="flex rounded-[18px] p-[10px] text-xs"
              data-testid="deleteSelected"
              onClick={() => {
                void (async () => {
                  const alert = await Swal.fire({
                    title: t('common:are_you_sure'),
                    icon: 'warning',
                    text: t('uncategorized:can_not_revert'),
                    showCancelButton: true,
                    confirmButtonColor: '#0D6EFD',
                    cancelButtonColor: '#6C757D',
                    confirmButtonText: t('common:confirm'),
                    cancelButtonText: t('common:cancel'),
                  })
                  if (alert.isConfirmed) {
                    void DELETE_SELECTED()
                  }
                })()
              }}
            >
              <span className="w-[18px]">
                <DeleteIcon />
              </span>
              <span className="pl-2 text-primaryTextColor">{t('common:delete')}</span>
            </button>
          </div>
        </>
      )}
    </div>
  )
}
