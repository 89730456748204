import classnames from 'classnames'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import { type Notification } from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'

function useNotificationLink(notification: Api.Notification): string {
  const profile = useAuth().profile
  const locale = useLocale()

  if (notification.type === 'ScoreSaveNotification') {
    return `/${locale}/student/courses/${notification.data.courseId!}/scores`
  }

  if (notification.type === 'MaterialAddNotification') {
    return `/${locale}/student/courses/${notification.data.courseId!}/materials`
  }

  if (notification.type === 'LectureStartsNotification') {
    const profileType =
      profile?.type === 'UserProfileLecturer'
        ? 'lecturer'
        : profile?.type === 'UserProfileStudent'
          ? 'student'
          : 'administrator'

    return `/${locale}/${profileType}/schedule`
  }

  if (notification.type === 'NewsAddNotification') {
    return `/${locale}/news/${notification.data.newsId!}`
  }

  throw new TypeError(`unhandled notification type: ${notification.type satisfies never as string}`)
}

export function NotificationsLink({ notification }: { notification: Api.Notification }): JSX.Element {
  const link = useNotificationLink(notification)
  const dateLocale = useDateLocale()
  const userState = useUserState()

  const headers = useAuthenticatedHeaders()

  function notificationClickHandler(notification: Notification): void {
    if (notification.readAt == null) {
      void Api.patchNotificationsMarkAsRead({
        headers,
        args: {
          uuid: notification.id,
        },
      }).then(() => {
        // update notifications count
        void userState.mutate()
      })
    }
  }

  return (
    <Link
      to={link}
      onClick={(): void => notificationClickHandler(notification)}
      className="relative mb-2 flex cursor-pointer items-center rounded-xl border-0"
      data-testid={`notification/${notification.id}`}
      state={{ isNotification: true }}
    >
      <div
        className={classnames(
          'hover-left-animation w-full rounded-xl border border-solid border-borderGrey p-4 hover:bg-hover-card',
          {
            'border-0 bg-card': notification.readAt == null,
          }
        )}
      >
        <div
          className={classnames('text-sm', {
            'text-primaryTextColor': notification.readAt == null,
          })}
        >
          <div
            className={classnames('mb-2 text-title font-bold text-lightPrimaryIconText', {
              'text-primaryTextColor': notification.readAt == null,
            })}
          >
            {notification.title}
          </div>
          <div
            className={classnames('text-title text-lightPrimaryIconText', {
              'text-primaryTextColor': notification.readAt == null,
            })}
          >
            {notification.text}
          </div>
        </div>
        <div className="mt-3 flex items-center text-sm text-lightPrimaryIconText">
          <span className="mr-1.5">
            <ClockIcon />
          </span>
          {format(parseISO(notification.createdAt), 'd MMMM yyyy, HH:mm', {
            locale: dateLocale,
          })}
        </div>
      </div>
      {notification.readAt == null ? <div className="absolute right-3 size-3 rounded-full bg-[#519ED9]" /> : null}
    </Link>
  )
}
