import classNames from 'classnames'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { useKey } from 'react-use'
import { CloseIcon } from 'src/assets/icons/customIcons/Close'
import { StatsIcon } from 'src/assets/icons/customIcons/Stats'
import { useTheme } from 'src/state/providers/Theme'

interface ModalProps {
  readonly title: string | React.ReactNode
  readonly icon?: JSX.Element
  readonly children?: React.ReactNode | undefined
  readonly isModalOpen: boolean
  readonly footer?: JSX.Element
  readonly onClose?: () => void
  readonly className?: string
  readonly height?: string | number
  readonly width?: string | number
  readonly testId?: string
}

export const Modal = ({
  title,
  icon,
  children,
  isModalOpen,
  footer,
  onClose,
  className,
  height,
  width,
  testId,
}: ModalProps): JSX.Element => {
  const theme = useTheme()

  useKey('Escape', () => onClose && onClose())

  return ReactDOM.createPortal(
    <div
      className={classNames(
        'fixed left-0 top-0 z-[11111] flex h-[100vh] w-[100vw] items-center justify-center bg-[#000]/50 duration-[200ms] ease-in-out',
        isModalOpen ? 'visible opacity-100' : 'invisible z-[-1] opacity-0',
        className
      )}
      data-testid={testId}
    >
      <div
        className={`modal-box max-h-[80%] w-full max-w-[94%] overflow-hidden rounded-card bg-primaryWhite duration-200 ease-in-out md:max-w-[80%] ${isModalOpen ? 'mb-0' : 'mb-5'}`}
        style={{ height, width }}
      >
        <div className="flex items-center justify-between border-b border-borderColor px-4 py-3">
          <div className="flex items-center">
            <div className="mr-2">{icon ?? <StatsIcon />}</div>
            <h5 className="text-headline font-bold xxs:text-[20px] xs:text-[20px]" data-testid="modalTitle">
              {title}
            </h5>
          </div>
          <button
            onClick={onClose}
            type="button"
            className="flex cursor-pointer items-center justify-center rounded-full p-[3px] duration-300 ease-in-out hover:bg-hover-icon"
            data-testid="closeModalButton"
          >
            <CloseIcon color={theme === 'dark' ? '#FFF' : '#000'} />
          </button>
        </div>
        <div className="modal-content max-h-[60vh] px-1 py-3 md:p-3">{children}</div>
        {footer != null && <div className="border-t px-4 py-3">{footer}</div>}
      </div>
    </div>,
    document.body
  )
}
