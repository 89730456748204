import * as React from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { Checkmark } from 'src/assets/icons/customIcons/Checkmark'
import Notifications from 'src/assets/icons/customIcons/page-icons/Notifications'
import { Settings } from 'src/assets/icons/customIcons/Settings'
import { actionProps } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import { NotificationsLink } from 'src/views/includes/header/NotificationsLink'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function NotificationsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('notification:notifications')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const userState = useUserState()
  const t = useTranslatable()
  const locale = useLocale()
  const [searchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!
  const headers = useAuthenticatedHeaders()
  const { pathname } = useLocation()

  const { data: notifications, mutate } = useApi({
    endpoint: Api.getNotifications,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          page: parseInt(page),
          perPage: parseInt(perPage),
        },
      }),
      [headers, page, perPage]
    ),
    shouldFetch: userState.data?.regime.functionalIsLimited === false,
  })

  useUserStateMutateOnMount()

  if (notifications?.data == null) return null

  const breadcrumbsItems = [{ page: `${t('notification:notifications')}`, path: pathname }]

  return (
    <Section
      title={t('notification:notifications')}
      breadcrubms={breadcrumbsItems}
      icon={<Notifications />}
      rightElement={
        <div className="ml-2 flex items-center justify-between">
          <div className="flex flex-wrap items-center">
            {notifications.data.length === 0 ? (
              ''
            ) : userState.data?.notificationsUnread != null && userState.data.notificationsUnread > 0 ? (
              <div className="my-3 mr-6 flex xxs:my-0 xxs:mr-2 xs:my-0 xs:mr-2">
                <a
                  className="flex rounded-[18px] px-3 py-2 hover:bg-card"
                  {...actionProps(
                    () =>
                      void Api.patchNotificationsMarkAllAsRead({
                        headers,
                      }).then(() => {
                        void mutate()
                        void userState.mutate()
                      })
                  )}
                  data-testid="markAllAsReadButton"
                >
                  <Checkmark />
                  <span className="ml-2 text-xs text-lightPrimaryIconText">{t('common:mark_as_read')}</span>
                </a>
              </div>
            ) : (
              <div className="my-3 mr-6 flex rounded-[18px] px-3 py-2 hover:bg-card">
                <span className="opacity-50">
                  <Checkmark />
                </span>
                <span className="ml-2 text-xs text-lightPrimaryIconText opacity-50">{t('common:mark_as_read')}</span>
              </div>
            )}
            <Link
              className="my-3 flex rounded-[18px] px-3 py-2 hover:bg-card xxs:px-0 xs:px-0"
              to={`/${locale}/profile/settings/notifications`}
            >
              <Settings />
              <span className="ml-2 text-xs text-lightPrimaryIconText">{t('system:settings')}</span>
            </Link>
          </div>
        </div>
      }
    >
      {notifications.data.length > 0 ? (
        <>
          <PaginationTop pagination={notifications.meta.pagination} />

          {notifications.data.map((notification) => (
            <NotificationsLink notification={notification} key={notification.id} />
          ))}
          <PaginationBottom pagination={notifications.meta.pagination} />
        </>
      ) : (
        <NoContent header={t('error:notifications_not_found')} image={Warning} />
      )}
    </Section>
  )
}
